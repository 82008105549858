import Layout from '../components/layout';
import Section from '../components/Section/section';
import SEO from '../components/seo';
import { Context } from '../Utils/Store/store';
import MobileCartButton from '../components/Blocks/MobileCartButton/mobileCartButton';
import { vkPixelPageView } from '../Utils/vkPixel';
import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

interface CategoriesTemplateProps {
  pageContext: any;
  data: any;
}

const CategoriesTemplate = ({ pageContext, data, location }: CategoriesTemplateProps) => {
  const { type } = pageContext;
  const { state } = useContext(Context);
  const cartIsNotEmpty = state.cart.length > 0;

  useEffect(() => {
    vkPixelPageView();
  }, []);

  return (
    <Layout location={location}>
      <SEO title={type} description={data.allStrapiCategories.nodes[0].Description} />
      {data && data.allStrapiCategories.nodes.map((val) => (
        <Section
          key={`category-${val.Name}-${val.id}`}
          withfilters
          timeConstrain={val.TimeConstrain}
          withsubcategories
          heading={type}
          products={val.products.filter((prod) => prod.Status && prod.Status.Status !== 'hide')}
        />
      ))}

      {cartIsNotEmpty && <MobileCartButton />}

    </Layout>
  );
};

export const query = graphql`query HomePageQuery($type: String) {
  allStrapiCategories(filter: {Name: {eq: $type}}) {
    nodes {
      Name
      TimeConstrain {
        To
        From
      }
      Description
      Status {
        Status
      }
      id
      products {
        IsSpicy
        IsVegan
        isFixed
        OldPrice
        Options {
          Name
          iikoID
          Price
          id
          Modificators {
            Name
            Price
            iikoModifID
          }
        }
        GroupOfMods {
          GroupName
          id
          Mod {
            Name
            Price
            id
            iikoModifID
          }
        }
        LongDescription
        Name
        SmallDescription
        Price
        iikoID
        Status {
          Status
        }
        Weight
        id
        mainingredients {
          Name
        }
        subcategory {
          Name
        }
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
        }
      }
    }
  }
}
`;

export default CategoriesTemplate;
